import { BaseSelectField } from "../../components/BaseSelectField/BaseSelectField";
// import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import { Fragment } from "react";
// import { Validator } from "devextreme-react";
// import { RequiredRule } from "devextreme-react/data-grid";

const BuildingStatusSelectField = (
    {
        enableSearch,
        label,
        optionUpdatedByUser,
        validationMethod,
        value,
        isVisible,
        readOnly,
        inputAttribute
    }
) => {
    const data = [
        'Active',
        'Inactive'
    ];
    const ruleMessage = 'A status must be chosen'

    return (
        <Fragment>
            <BaseSelectField
                inputAttribute={{ id: "building_status_dropdown" }}
                data={data}
                enableSearch={enableSearch}
                label={label}
                onInitialized={(e) => e.component.validate()}
                onOptionChanged={(e) => e.component.validate()}
                optionUpdatedByUser={optionUpdatedByUser}
                validationRuleCallback={() => !IsNullOrEmpty(value)}
                value={value}
                isVisible={isVisible}
                readOnly={readOnly}
            />
            {/* <Validator
                    onInitialized={(e) => e.component.validate()}
                    onOptionChanged={(e) => e.component.validate()}
                > */}
            {/* <RequiredRule type='required' />  */}
            {/* <CustomRule
                        message={ruleMessage}
                        validationCallback={() => validationMethod(value)}
                        reevaluate={true}
                    />                        */}
            {/* </Validator>  */}

        </Fragment>
    )
}

export default BuildingStatusSelectField;