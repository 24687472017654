import React from "react";
import { FormControl } from "@mui/material";
import { SelectBox } from "devextreme-react";
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import { usStates } from "../util/StateAbbreviations";
import IsNullOrEmpty from "../../components/IsNullOrEmpty";
import { BaseSelectField } from "../../components/BaseSelectField/BaseSelectField";

const US_StatesSelectField = (
    {        
        enableSearch,
        isVisible, 
        optionUpdatedByUser,
        validationMethod,
        inputAttribute,
        value
    }
) => {
    const data = [
        ...usStates
    ];

    const abbreviatedStates = data.map((values) => values.value);

    const formLabel = "State (Abbrev.) *";

    return (
        <>
        {/* <FormControl
            required
            sx={{
                minWidth: 120
                , mr: 1
                , mt: 1
            }}
            error={IsNullOrEmpty(value)}
            > */}
            <BaseSelectField 
                inputAttribute={{ id: "state_dropdown" }}
                data={abbreviatedStates}
                isVisible={isVisible}
                label={formLabel}
                enableSearch={enableSearch}
                onInitialized={(e) => e.component.validate()}
                onOptionChanged={(e) => e.component.validate()}
                optionUpdatedByUser={optionUpdatedByUser}
                validationRuleCallback={() => !IsNullOrEmpty(value)}
                value={value}
            />
        {/* <SelectBox
            label={formLabel}
            dataSource={abbreviatedStates}
            searchEnabled={true}
            height={56}
            width={188}
            value={value}
            id="building_state_textBox_016"
            onValueChange={(value) => {
                setter(value)
            }}
            >
            <Validator
                onInitialized={(e) => e.component.validate()}
                onOptionChanged={(e) => e.component.validate()}
                >
                <RequiredRule type='required' />
                <CustomRule
                    message="State is required"
                    validationCallback={() => validationMethod(value) }
                    reevaluate={true}
                    />
            </Validator>
        </SelectBox> */}
        {/* </FormControl> */}
        </>
    );
};

export default US_StatesSelectField;