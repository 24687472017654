const FiscalYearList = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const fiscalYears = [];

    let fiscalYear = currentMonth >= 9 ? currentYear + 1 : currentYear;

    for (let i = 0; i < 10; i++) { 
      fiscalYears.push(fiscalYear - i) 
    };
    
    return fiscalYears;
};

export default FiscalYearList;