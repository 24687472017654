import { useEffect, useState } from 'react';
import { ROUTE_BASIC_SECURITY_REPORT } from '../../routes/Routes';
import usePageTitle from '../../hooks/usePageTitle';
import GetFetch from '../../hooks/GetFetch';
import { BaseSelectField } from '../../components/BaseSelectField/BaseSelectField';
import FiscalYearDropdown from '../../FyCostEstimates/AddSecurityCost/FiscalYearDropdown';
import DataGrid, {
    Column,
    Export,
    KeyboardNavigation,
    Pager,
    Paging,
    SearchPanel
} from 'devextreme-react/data-grid';
import { BaseButton } from '../../components/BaseButton/BaseButton';
import CustomPreparedHeaderCell from '../../components/CustomPreparedHeaderCell';
import { allowedPageSizes, pageFormats } from '../../components/GlobalDataGridConfigurations';
import { logExport, useAuth } from '../../hooks/useAuth';
import ExportGrid from '../../components/ExportGrid';
import './BasicSecurityReport.scss';


const BasicSecurityReport = () => {
    const { title } = ROUTE_BASIC_SECURITY_REPORT;
    const { user } = useAuth();
    
    const [agencyName, setAgencyName] = useState(null);
    const [dataSource, setDataSource] = useState();
    const [fiscalYear, setFiscalYear] = useState(null);
    const [selectValues, setSelectValues] = useState([]);
    
    useEffect(() => { getAgencyNames() }, []);
    usePageTitle( title );

    const getAgencyNames = async () => {
        try {
            const { Errors, Message, Success } = await GetFetch('/v1/BasicSecurity/GetAgencyNames');
            if (Success) {
                const agencyNames = Message.map((props) => { return props.AgencyName });
                const uniqueArray = ['All', ...new Set(agencyNames)];
                setSelectValues(uniqueArray);
            };

            if (Errors) console.error("Errors: ", Errors);
        } catch (error) {
            console.error(error)
        }       
    };

    const onSubmit = async () => {
        try {
            const { Errors, Message, Success } = await GetFetch(`/v1/BasicSecurity/Report?agencyName=${agencyName}&billingDate=${String(fiscalYear)}`);

            if (Success) {
                const res = Message.map(item => 
                    Object.fromEntries(
                        Object.entries(item).map(([key, value]) =>
                            [key, value]
                        )
                    )
                );

                setDataSource(res);
            };

            if (Errors) console.error("errors: ", Errors);

        } catch (error) {
            console.error(error);
        }
    };

    const handleExport = (e) => {
        logExport(user.email)
        ExportGrid(e, title, true, 12)
    };

    return (
        <>
            <div className="inline__div--wrapper">
                <h1> { title } </h1>
            </div>
            <div className="inline__div--wrapper">
                <BaseSelectField 
                    data={selectValues}
                    inputAttribute={
                        { 
                            "aria-label": "agency name select",
                            "aria-required": "false" 
                        }
                    }
                    label={"Agency Name"}
                    optionUpdatedByUser={({ selectedItem }) => setAgencyName(selectedItem)}
                    value={agencyName}

                />
                <FiscalYearDropdown 
                    enableSearch={true}
                    inputAttribute={{ "aria-label": "fiscal year select" }}
                    label={"Fiscal Year"}
                    optionUpdatedByUser={({ selectedItem }) => setFiscalYear(selectedItem)}
                    value={fiscalYear}
                    isVisible={true}
                />
            </div>
            <div className="inline__div--wrapper">
                <BaseButton 
                    label={"Generate Report"}
                    inputAttribute={{ "aria-label": "generate report button" }}
                    disabled={true ? (!agencyName || !fiscalYear) : (agencyName && fiscalYear)}
                    variant={"outlined"}
                    onClick={onSubmit}
                />
            </div>
            {
                dataSource &&
                <div className="inline__div--wrapper">
                    <DataGrid 
                        columnAutoWidth={true}
                        dataSource={dataSource}
                        onCellPrepared={CustomPreparedHeaderCell}
                        onExporting={e => handleExport(e)}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        showColumnLines={true}
                        showRowLines={true}
                    >
                        <Column 
                            dataField="Id"
                            visible={false}
                        />
                        <Column 
                            caption='Agency Name'
                            dataField='AgencyName'
                        />
                        <Column 
                            caption='Bureau Name'
                            dataField='BureauName'
                        />
                        <Column 
                            caption='Fiscal Year'
                            dataField='FiscalYear'
                        />
                        <Column 
                            caption='Building Number'
                            dataField='BuildingNumber'
                        />
                        <Column 
                            caption='AGBU Code'
                            dataField='AGBUCode'
                        />
                        <Column 
                            caption='Security Assessment Amount'
                            dataField='SecurityAssessmentAmount'
                        />
                        <KeyboardNavigation enabled={true} />
                        <SearchPanel 
                            highlightCaseSensitive={true}
                            visible={true}
                        />
                        <Pager 
                            allowedPageSizes={allowedPageSizes}
                            showPageSizeSelector={true}
                        />
                        <Paging 
                            defaultPageSize={10}
                        />
                        <Export 
                            enabled={true}
                            formats={pageFormats}
                        />
                    </DataGrid>
                </div>
            }
        </>
    )
};

export default BasicSecurityReport;