import React from 'react';
import ModuleItem from './ModuleItem.js';

export const PROFILE_MENU = {
    key: 1,
    icon: "accountbox",
    title: "Dashboard"
};
export const BUILDINGS_MENU = {
    key: 2,
    icon: "home",
    title: "Buildings & Customers"
};
export const CONTRACTS_MENU = {
    key: 3,
    icon: "ordersbox",
    title: "Contracts & Post Exhibits"
};
export const BILLINGS_MENU = {
    key: 4,
    icon: "exportxlsx",
    title: "Billing & Adjustments"
};
export const FYCOSTS_MENU = {
    key: 5,
    icon: "money",
    title: "Fiscal Year Costs"
};
export const SECURITY_MENU = {
    key: 6,
    icon: "toolbox",
    title: "Security Admin"
}
export const ADMIN_MENU = {
    key: 7,
    icon: "toolbox",
    title: "System Admin"
};
export const HELP_MENU = {
    key: 8,
    icon: "help",
    title: "Help"
};
const MenuItem = ({ data }) => {
    const arr1 = new Map(Object.entries(data.modules));
    const arr2 = Array.from(arr1, ([key, value]) => (key, value={ id: value.id, name: value.name, path: value.path, allowed: value.allowed}));
    const moduleItems = arr2.map((value) => (
        <ModuleItem
          key={value.id}
          prop={value}
        />
      ));
      // TO-DO: Add aria-label, use [data.key.title + " Tab"] for tab name
      return <div className="tabpanel-item">{moduleItems}</div>;
}

export default MenuItem;