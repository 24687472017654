import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTE_REGION_AND_BUILDING_DATA } from '../routes/Routes';
import { Button } from '@mui/material';
import { useAuth } from '../hooks/useAuth';
import PutPostPatchFetch from '../hooks/PutPostPatchFetch';
import SingleRegionSelectField from './components/SingleRegionSelectField/SingleRegionSelectField';
import US_StatesSelectField from './components/US_StatesSelectField';
import BuildingStatusSelectField from './components/BuildingStatusSelectField';
import IsNullOrEmpty from '../components/IsNullOrEmpty';
import { NumberBox, TextBox } from 'devextreme-react';
import Box, {
    Item,
} from 'devextreme-react/box';
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import ClosingAlert from '../components/ClosingAlert';
import { BaseButton } from '../components/BaseButton/BaseButton';
import { Content } from '../Enums/contents';


const {
    Buttons: {
        cancel,
        submit,
        MUI: {
            Severity: {
                success,
                warning
            },
            Variant: {
                outlined
            }
        }
    },
    Inputs: {
        Input_SelectField_Labels: {
            singleRegionLabel,
            statusLabel,
            usStateLabel
        }
    }
} = Content;

const BuildingsAdd = (props) => {
    const { user } = useAuth();
    const [buildingSeqNumber, setBuildingSeqNumber] = useState("");
    const [buildingName, setBuildingName] = useState("");
    const [facility, setFacility] = useState("");
    const [region, setRegion] = useState(0);
    const [status, setStatus] = useState("");

    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [zipCode, setZipCode] = useState();
    const [state, setState] = useState("");
    const [cityName, setCityName] = useState("");

    const [adjustedRentSqFt, setAdjustedRentSqFt] = useState(0);
    const [adjustedUsageSqFt, setadjustedUsageSqFt] = useState(0);
    const [ansiRentSqFt, setAnsiRentSqFt] = useState(0);
    const [ansiUsableSqFt, setAnsiUsableSqFt] = useState(0);
    const [joinUseSqFt, setJoinUseSqFt] = useState(0);
    const [commJointUseSqFt, setCommJointUseSqFt] = useState(0);
    const [leaseJointUseSqFt, setLeaseJointUseSqFt] = useState(0);
    const [rentUsageFactor, setRentUsageFactor] = useState(0);

    const [congressionalRep, setCongressionalRep] = useState("");

    const [buildingSaved, setBuildingSaved] = useState(false);
    const [buildingSaveFail, setBuildingSaveFail] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [canSubmit, setCanSubmit] = useState(false);
    const [canDeactivate, setCanDeactivate] = useState(false);

    const navigate = useNavigate();
    const title = "Add a Building";

    useEffect(() => {
        document.title = title;
        const route = "ROUTE_REGION_AND_BUILDING_DATA"
        setCanDeactivate(user.role.ModulePermissions.filter(module => { return (module.Page === route && module.Deactivate) }).length > 0)
    }, []);

    useEffect(() => {
        BuildingCanBeSubmitted();
    }, [
        buildingSeqNumber
        , buildingName
        , facility
        , region
        , status
        , address1
        , cityName
        , state
        , zipCode
    ]);
    // Top Level If statement closes the form after building has been submitted/saved.
    if (buildingSaved) {
        setTimeout(() => {
            closeForm();
        }, 1000);
    }

    const BuildingCanBeSubmitted = () => {
        const result = (
            isValidBldgPattern(buildingSeqNumber)
            && !IsNullOrEmpty(buildingName)
            && validFacility(facility)
            && validRegion(region)
            && validStatus(status)
            && !IsNullOrEmpty(address1)
            && !IsNullOrEmpty(cityName)
            && validStateAbbr(state)
            && validZipCode(zipCode)
        );
        setCanSubmit(result);
    }

    const validFacility = (value) => {
        if (IsNullOrEmpty(value)) {
            return false;
        }
        if (value.length > 4) {
            return false;
        }
        return true;
    }

    const validStatus = (value) => {
        if (IsNullOrEmpty(value) && canDeactivate) {
            return false;
        };
        
        return true;
    };
      
    const validRegion = (value) => {
        return value > 0;
    }
    const validStateAbbr = (value) => {
        if (IsNullOrEmpty(value)) {
            return false;
        }
        if (value.length !== 2) {
            return false;
        }
        return true;
    }
    const validZipCode = (value) => {
        if (value === undefined) {
            return false;
        }
        let stringValue = `${value}`
        if (IsNullOrEmpty(stringValue)) {
            return false;
        }
        if (stringValue.length === 5) {
            return true;
        }
        if (stringValue.length === 9) {
            return true;
        }
        return false;
    }

    const NonNegative = (value) => {
        if (value < 0) return 0
        else return value;
    }
    const isValidBldgPattern = (input) => {
        if (input.length === 6) {
            const firstTwoCharacters = input.substring(0, 2);
            const lastFourCharacters = input.substring(2);
            const firstFourCharacters = input.substring(0, 4);
            const lastTwoCharacters = input.substring(4);
            if (
                areCapitalLetters(firstTwoCharacters) && areFourNumbers(lastFourCharacters)
                || areFourNumbers(firstFourCharacters) && areCapitalLetters(lastTwoCharacters)) {
                return true;
            }
        }
        return false;
    }
    const devExtremeCallbackBldgSeqNum = (input) => {
        return isValidBldgPattern(input.value);
    }

    const areCapitalLetters = (char) => /^[A-Z]{2}$/.test(char);
    const areFourNumbers = (char) => /^\d{4}$/.test(char);

    const SubmitData = async () => {
        if (!canSubmit) {
            console.log('Cant be submitted', buildingObject)
            return;
        }

        const buildingObject = {
            "SequenceNumber": buildingSeqNumber,
            "Name": buildingName,
            "Status": canDeactivate? status:"Active",
            "Facility": facility,
            "RegionNumber": region,
            "Location": {
                "Id": 0,
                "Address1": address1,
                "Address2": address2,
                "CityName": cityName,
                "State": state,
                "ZipCode": zipCode
            },
            "RentUsageFactor": rentUsageFactor,
            "AdjustedRentSqFt": adjustedRentSqFt,
            "AdjustedUsageSqFt": adjustedUsageSqFt,
            "AnsiRentSqFt": ansiRentSqFt,
            "AnsiUsableSqFt": ansiUsableSqFt,
            "JointUseSqFt": joinUseSqFt,
            "CommunityJointUseSqFt": commJointUseSqFt,
            "LeaseJointUseSqFt": leaseJointUseSqFt,
            "CongressionalRep": congressionalRep
        };

        const save = await PutPostPatchFetch('/v1/Building/addBuilding', 'POST', buildingObject);

        if (save === undefined) {
            setBuildingSaved(false);
            setBuildingSaveFail(true);
            setErrorMessage("Something went wrong... Please correct your inputs and try again");
            return;
        }

        if (save.Success) {
            setBuildingSaved(true);
            setBuildingSaveFail(false);
        }
        else {
            setBuildingSaved(false);
            setBuildingSaveFail(true);
            setErrorMessage(save.Errors[0].Message);
        }
    }

    function closeForm() {
        props.Close();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            SubmitData();
        };
    };

    const inputProps = {
        onKeyDown: handleKeyDown,
        "aria-required": "true"
    };
    
    return (
        <div>
            <h3 style={{ color: "#06152B" }}>{title}</h3>
            <div className="block--flex-items" style={{ maxWidth: 1500 }}>
                <Box 
                    className='row-one-building-seq-num'
                    direction="row"
                    height={100}
                >
                    <Item ratio={1} shrink={1}>
                        <TextBox
                            label='Building Number *'
                            labelMode='floating'
                            value={buildingSeqNumber}
                            height={56}
                            maxLength={6}
                            width={188}
                            valueChangeEvent='input'
                            ignoreEmptyValue={false}
                            inputProps={inputProps}
                            id="building_number_textBox_009"
                            onValueChange={(value) => {
                                const inputVal = value.toLocaleUpperCase();
                                setBuildingSeqNumber(inputVal);
                            }}
                            length={6}
                        >
                            <Validator
                                onInitialized={(e) => e.component.validate()}
                                onOptionChanged={(e) => e.component.validate()}
                            >
                                <RequiredRule type='required' />
                                <CustomRule
                                    message='Valid Pattern EX1234 or 1234EX'
                                    validationCallback={devExtremeCallbackBldgSeqNum}
                                    reevaluate={false}
                                />
                            </Validator>
                        </TextBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <TextBox
                            label='Name *'
                            labelMode='floating'
                            value={buildingName}
                            height={56}
                            width={188}
                            inputProps={inputProps}
                            valueChangeEvent='input'
                            id="building_name_textBox_010"
                            onValueChange={(value) => {
                                setBuildingName(value);
                            }}
                        >
                            <Validator
                                onInitialized={(e) => e.component.validate()}
                                onOptionChanged={(e) => e.component.validate()}
                            >
                                <RequiredRule type='required' />
                                <CustomRule
                                    message="Building name can't be empty"
                                    validationCallback={() => !IsNullOrEmpty(buildingName)}
                                    reevaluate={true}
                                />
                            </Validator>
                        </TextBox>
                    </Item>
                    {canDeactivate &&
                        <Item ratio={1} shrink={1}>
                            <BuildingStatusSelectField
                                isVisible={true}
                                label={statusLabel}
                                optionUpdatedByUser={({selectedItem}) => setStatus(selectedItem)}
                                validationMethod={validStatus}
                                value={status}
                            />
                        </Item>
                    }
                    <Item ratio={1} shrink={1}>
                        <TextBox
                            label='Facility *'
                            labelMode='floating'
                            value={facility}
                            maxLength={4}
                            height={56}
                            width={188}
                            valueChangeEvent='input'
                            id="building_facility_textBox_012"
                            onValueChange={(value) => {
                                setFacility(value)
                            }}
                            inputProps={inputProps}
                        >
                            <Validator
                                onInitialized={(e) => e.component.validate()}
                                onOptionChanged={(e) => e.component.validate()}
                            >
                                <RequiredRule type='required' />
                                <CustomRule
                                    message="Required, 4 character max"
                                    validationCallback={() => validFacility(facility)}
                                    reevaluate={true}
                                />
                            </Validator>
                        </TextBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <SingleRegionSelectField 
                            isVisible={true}
                            label={singleRegionLabel}
                            optionUpdatedByUser={({selectedItem}) => setRegion(selectedItem)}
                            value={region} 
                            validationMethod={validRegion}
                        />
                    </Item>
                </Box>
                <Box
                    className='row-two'
                    direction="row"
                    height={100}
                >
                    <Item ratio={1} shrink={1}>
                        <TextBox
                            label='Address 1 *'
                            labelMode='floating'
                            value={address1}
                            height={56}
                            width={188}
                            inputProps={inputProps}
                            valueChangeEvent='input'
                            id="building_address1_textBox_014"
                            onValueChange={(value) => {
                                setAddress1(value);
                            }}
                        >
                            <Validator
                                onInitialized={(e) => e.component.validate()}
                                onOptionChanged={(e) => e.component.validate()}
                            >
                                <RequiredRule type='required' />
                                <CustomRule
                                    message="Required"
                                    validationCallback={() => !IsNullOrEmpty(address1)}
                                    reevaluate={true}
                                />
                            </Validator>
                        </TextBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <TextBox
                            label='Address 2'
                            labelMode='floating'
                            id="building_address2_textBox"
                            value={address2}
                            height={56}
                            width={188}
                            valueChangeEvent='input'
                            onValueChange={(value) => {
                                setAddress2(value);
                            }}
                        />
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <TextBox
                            label="City *"
                            labelMode='floating'
                            value={cityName}
                            height={56}
                            width={188}
                            inputProps={inputProps}
                            valueChangeEvent='input'
                            id="building_city_textBox_015"
                            onValueChange={(value) => {
                                setCityName(value)
                            }}
                        >
                            <Validator
                                onInitialized={(e) => e.component.validate()}
                                onOptionChanged={(e) => e.component.validate()}
                            >
                                <RequiredRule type='required' />
                                <CustomRule
                                    message="Required"
                                    validationCallback={() => !IsNullOrEmpty(cityName)}
                                    reevaluate={true}
                                />
                            </Validator>
                        </TextBox>
                    </Item>
                    <Item ratio={1} shrink={2}>
                        <US_StatesSelectField 
                            isVisible={true}
                            label={usStateLabel}
                            optionUpdatedByUser={({selectedItem}) => setState(selectedItem)}
                            validationMethod={validStateAbbr} 
                            value={state} 
                        />
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <TextBox
                            label='Zip Code *'
                            labelMode='floating'
                            maxLength={10}
                            value={zipCode}
                            height={56}
                            width={188}
                            valueChangeEvent='input'
                            id="building_zipcode_textBox_017"
                            onValueChange={(value) => {
                                setZipCode(NonNegative(value));
                            }}
                            inputProps={{
                                onKeyDown: handleKeyDown,
                                "aria-required": "true"
                            }}
                        >
                            <Validator
                                onInitialized={(e) => e.component.validate()}
                                onOptionChanged={(e) => e.component.validate()}
                            >
                                <RequiredRule type='required' />
                                <CustomRule
                                    message="Required, either 5 or 9 digits"
                                    validationCallback={() => validZipCode(zipCode)}
                                    reevaluate={true}
                                />
                            </Validator>
                        </TextBox>
                    </Item>
                </Box>

                <Box
                    className='row-three-rent-usage-to-ansi-rent-squrare-ft'
                    direction="row"
                    height={100}
                    width={1000}
                >
                    <Item ratio={1} shrink={1}>
                        <TextBox
                            label='Congressional Representative'
                            labelMode='floating'
                            id="Congressional_Representative"
                            value={congressionalRep}
                            maxLength={25}
                            height={56}
                            width={220}
                            valueChangeEvent='input'
                            onValueChange={(value) => {
                                setCongressionalRep(value);
                            }}
                        >
                        </TextBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <NumberBox
                            value={rentUsageFactor}
                            format="###,###.##"
                            id="Rent_Usage_Factor"
                            height={56}
                            width={188}
                            max={999999999}
                            onValueChanged={e => setRentUsageFactor(e.value)}
                            label="Rent Usage Factor"
                            labelMode="floating"

                        >

                        </NumberBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <NumberBox
                            value={adjustedRentSqFt}
                            min={0}
                            format="###,###,###.##"
                            id="Adjusted_rent"
                            height={56}
                            width={188}
                            max={999999999}
                            onValueChanged={e => setAdjustedRentSqFt(e.value)}
                            label="Adjusted Rent Sq. Ft."
                            labelMode="floating"

                        ></NumberBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <NumberBox
                            value={adjustedUsageSqFt}
                            min={0}
                            format="###,###,###.##"
                            id="Adjusted_usage"
                            height={56}
                            width={188}
                            max={999999999}
                            onValueChanged={e => setadjustedUsageSqFt(e.value)}
                            label="Adjusted Usage Sq. Ft"
                            labelMode="floating"

                        ></NumberBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <NumberBox
                            value={ansiRentSqFt}
                            min={0}
                            id="Ansi_rent"
                            format="###,###,###.##"
                            height={56}
                            width={188}
                            max={999999999}
                            onValueChanged={e => setAnsiRentSqFt(e.value)}
                            label="Ansi Rent Sq. Ft."
                            labelMode="floating"

                        ></NumberBox>
                    </Item>
                </Box>
                <Box className='row-four-ansi-usable-to-least-joint'
                    direction="row"
                    height={100}
                    width={1000}
                >
                    <Item ratio={1} shrink={1}>
                        <NumberBox
                            value={ansiUsableSqFt}
                            min={0}
                            id="Ansi_usable"
                            format="###,###,###.##"
                            height={56}
                            width={188}
                            max={999999999}
                            onValueChanged={e => setAnsiUsableSqFt(e.value)}
                            label="Ansi Usable Sq. Ft."
                            labelMode="floating"

                        ></NumberBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <NumberBox
                            value={joinUseSqFt}
                            min={0}
                            id="Joint_use"
                            format="###,###,###.##"
                            height={56}
                            width={188}
                            max={999999999}
                            onValueChanged={e => setJoinUseSqFt(e.value)}
                            label="Joint Use Sq. Ft."
                            labelMode="floating"

                        ></NumberBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <NumberBox
                            value={commJointUseSqFt}
                            min={0}
                            id="Community_joint_use"
                            format="###,###,###.##"
                            height={56}
                            width={188}
                            max={999999999}
                            onValueChanged={e => setCommJointUseSqFt(e.value)}
                            label="Community Joint Use Sq. Ft."
                            labelMode="floating"

                        ></NumberBox>
                    </Item>
                    <Item ratio={1} shrink={1}>
                        <NumberBox
                            value={leaseJointUseSqFt}
                            min={0}
                            id="Lease_joint_use"
                            format="###,###,###.##"
                            height={56}
                            width={188}
                            max={999999999}
                            onValueChanged={e => setLeaseJointUseSqFt(e.value)}
                            label="Lease Joint Use Sq. Ft."
                            labelMode="floating"

                        ></NumberBox>
                    </Item>
                </Box>
            </div>
            <div className="block--flex-items">
                <Button variant="contained" onClick={SubmitData} disabled={!canSubmit} id="submit_button_018">Submit</Button>
                <Button variant="contained" onClick={() => { props.Close() }} id="cancel_button_019">Cancel</Button>
            </div>
            <ClosingAlert
                severity="success"
                message="Building was successfully submitted!"
                visible={buildingSaved}
                hideAlert={() => { setBuildingSaved(false) }}
            />
            <ClosingAlert
                severity="warning"
                message={errorMessage}
                visible={buildingSaveFail}
                hideAlert={() => { setBuildingSaveFail(false) }}
            />
        </div >
    );
}
export default BuildingsAdd;